/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useState, useEffect, SyntheticEvent,
} from 'react';
import { OptionType, ValueType } from '@atlaskit/select';
import {
  isUndefined, isNull, get, min,
} from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone';
import { useOrdersQuery, useGetOrderStatusQuery } from '../../graphql/types';
import { OrdersProps, Order } from './types';
import { DATES_DEFAULT, SEARCH_TYPE } from './widget/AdvancedFilter';
import { defaultColumns, getHeadAndRow } from './helper/getHeadAndRow';
import getPages from './helper/getPages';
import { DS_ORDER_COLUMNS, DEFAULT_STAT, COMPACT_MODEL_COLUMNS } from './config/config';
import OrdersListView from './views/ListView/ListView';
import SplitView from '../SplitView/SplitView';
import OrderView from './views/DetailedView/OrderView';
import ExportReport from '../ExportReports/ExportReport';

export default (props: OrdersProps) => {
  const { teamID, itemsPerPage = 20 } = props;
  /**
   * when on spitview, should turn isCompact to true;
   */
  const [isCompact, setIsCompact] = useState(false);
  /**
   * initilize columns specs (had incorporated localstorage settigs)
   */
  const [columns, setColumns] = useState(defaultColumns);
  /**
   * to control which page is currently on
   */
  const [currentPage, setCurrentPage] = useState(0);
  /**
   * to control the advanced Options show/hide
   */
  const [isOpen, setIsOpen] = useState(false);
  /**
   * highlight selected table rows
   */
  const [highlightedRowIndex, setHighlightedRowIndex] = useState<number>();

  const [isRefetching, setIsRefetching] = useState(false);

  /**
   * toggle api param pinShipByOrder
   */
  const [shouldPinShipByOrder, setShouldPinShipByOrder] = useState(false);
  /**
   * will load order when currentOrder`ID changes
   * this is used to pass the order detail view
   */
  const [order, setOrder] = useState<Order|undefined>(undefined);

  /** ******************************************************
   * start of query params
   ******************************************************** */
  const [skip, setSkip] = useState(0);
  const [take] = useState(itemsPerPage);
  const [sortKey, setSortKey] = useState<string | undefined>('createdAt');
  const [sortOrder, setSortOrder] = useState<'DESC' | 'ASC' | undefined>('DESC');

  const orderStatusList = useGetOrderStatusQuery({
    variables: {
      teamId: teamID,
    },
  });
  // all the status options
  const [status, setStatus] = useState(DEFAULT_STAT);

  // load customized order status list
  useEffect(() => {
    const { data } = orderStatusList;
    try {
      const orderStatus = data?.GetOrderStatus.orderStatus;

      if (
        !isUndefined(orderStatus)
        && !isNull(orderStatus)
        && orderStatus.length > 0
      ) {
        setStatus(
          DEFAULT_STAT.map((cur) => {
            const { code } = cur;
            let value = '';
            const { name } = cur;
            orderStatus.forEach((e) => {
              if (e.code === code) {
                value = e.id;
                // name = e.name;
              }
            });
            return {
              ...cur,
              value,
              name,
            };
          }),
        );
      }
    } catch (error) {
      // do nothing
    }
  }, [orderStatusList]);

  // control the serach input ui
  // not used for acture query param
  const [searchInput, setSearchInput] = useState('');
  // delay 800 ms set keyword when searchInput change
  // this is the real query variable
  const [keyword, setKeyword] = useState('');
  // to store timmer id
  const [debonced, setDebonced] = useState<number>();
  /**
   * to store the current selected orderID
   * on every time the onSelect be called should call this aswell
   * use this to set highlight which row
   */
  const [currentOrderID, setCurrentOrderID] = useState<string>();
  // for date filter query param
  const [
    dateSelectedValue,
    setDateSelectedValue,
  ] = React.useState<ValueType<OptionType>>();
  const [
    xeroSyncedValue,
    setXeroSyncedValue,
  ] = React.useState<ValueType<OptionType>>();
  // for teamChannel filter query param
  const [
    teamChannelInput,
    setTeamChannelInput,
  ] = React.useState<Array<OptionType>>([]);
  let teamChannelIDs: string[] = [];
  try {
    teamChannelIDs = teamChannelInput.map((option: OptionType) => String(option.value ?? ''));
  } catch (error) {
    teamChannelIDs = [];
  }

  // advancedFilterType is to select which field for detailed search, eg. name, email...
  const [
    advancedFilterType,
    setAdvancedFilterType,
  ] = useState<ValueType<OptionType>>(SEARCH_TYPE[0]);
  // advancedFilterInputs is purely for input control, only on certain condition will pass to advancedFilterQuery
  const [advancedFilterInputs, setAdvancedFilterInputs] = useState('');
  // advancedFilterQuery is the real query string we send to API
  const [advancedFilterQuery, setAdvancedFilterQuery] = useState('');

  const resetQuaryAndSetAdvancedFilterQuery = (value:string) => {
    setSkip(0);
    setCurrentPage(0);
    setAdvancedFilterQuery(value);
  };

  let advancedFilter = {};
  if (advancedFilterQuery) {
    advancedFilter = {
      [
      (advancedFilterType as OptionType).value ?? SEARCH_TYPE[0].value
      ]: advancedFilterQuery,
    };
  }
  /** ******************************************************
 * End of query params
 ******************************************************** */
  /**
   * fetched data with above filterst
   */
  const {
    data, loading, error, refetch,
  } = useOrdersQuery({
    variables: {
      teamID,
      skip,
      take,
      sortKey,
      sortOrder,
      status: status.map((cur) => (cur.isSelected ? cur.value : '')).filter((cur) => cur.length > 0),
      keyword,
      date: String((dateSelectedValue as OptionType)?.value ?? ''),
      isXeroSynced: String((xeroSyncedValue as OptionType)?.value ?? ''),
      teamChannelIDs,
      ...advancedFilter,
      pinShipByOrder: shouldPinShipByOrder,
      timeZone: moment.tz.guess(),
    },
    fetchPolicy: 'network-only',
  });
  /**
   * calculation for total orders
   */
  const ordersTotal = get(data, 'Orders.total', 0);
  const cursorFrom = data?.Orders && data?.Orders?.total > 0
    ? skip + 1
    : 0;
  const cursorTo = min([(cursorFrom + take - 1), ordersTotal]);

  const handleTableRowClick = React.useCallback(
    (o: Order) => {
      setCurrentOrderID(o.id);
    },
    [setCurrentOrderID],
  );
  const totalValue = get(data, 'Orders.totalAmtInclTax', 0);
  const totalOutstanding = get(data, 'Orders.totalOutstanding', 0);

  /**
   * generate the table head and rows with the
   * configs given in columns variable
   * will auto rerender with the changes in data
   */
  const { head, rows } = getHeadAndRow(data, columns, handleTableRowClick);

  /**
   * pages used to generate pagination componnent
   * will auto update with the changes in data
   */
  const pages = getPages(data, take);
  /**
   * mannualy set the skip when navigate page
   * also update the currentPage   *
   */
  const handlePageChange = async (event: SyntheticEvent, newPage: number) => {
    setSkip((newPage - 1) * take);
    setCurrentPage(newPage - 1);
  };
  /**
   * save columns show/hide option in localstorage
   */
  const saveColumnsOptionsStorage = (c: typeof columns) => {
    const settings = c.filter((cur) => cur.dataSource.length > 0)
      .map(({ key, isChecked }) => ({ key, isChecked }));

    window.localStorage.setItem(
      DS_ORDER_COLUMNS,
      JSON.stringify(settings),
    );
  };
  /**
   * to Handle the changes when tick / untick columns,
   * basicly idea when tick/untick,will change columsn[x].checked
   * and save changes in storage
   */
  const onColumnTick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target; // the name of the checkbox
    const newColumns = [...columns];
    newColumns.forEach((cur, index) => {
      if (cur.key === name) {
        newColumns[index].isChecked = e.target.checked;
        if (e.target.checked === false && sortKey === cur.key) {
          // if turn off the display of currently sorting column
          // need to reset the sortKey
          setSortKey(undefined);
          setSortOrder(undefined);
        }
      }
    });
    setColumns(newColumns);
    saveColumnsOptionsStorage(newColumns);
  };
  // the native type defination for onSort props from atlaskit is 'data:any'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSort = (d: any) => {
    setSortKey(d.key);
    setSortOrder(d.sortOrder);
  };

  const onStatusClick = (name: string) => {
    // status[0] will alway be 'ALL'
    const newStatus = [...status];
    // if 'All'
    if (name === 'All') {
      newStatus.forEach((_, i) => {
        newStatus[i].isSelected = false;
      });

      newStatus[0].isSelected = true;
    } else {
      // if NOT 'All'
      newStatus.forEach((e, i) => {
        if (e.name === name) {
          newStatus[i].isSelected = !newStatus[i].isSelected;
        }
      });
    }

    // to check when all other status is off
    // the 'All' stat should be on and vice versa
    if (!(newStatus.map((e) => e.isSelected).includes(true, 1))) {
      newStatus[0].isSelected = true;
    } else {
      newStatus[0].isSelected = false;
    }
    setSkip(0);
    setCurrentPage(0);
    setStatus(newStatus);
  };

  const resetQueryAndSetKeyword = (value:string) => {
    setSkip(0);
    setCurrentPage(0);
    setKeyword(value);
  };

  // global text search triger now
  const searchNow = () => {
    clearTimeout(debonced);
    resetQueryAndSetKeyword(searchInput);
  };
  // global text search UI control and debonced event emiter
  const onSearchTextChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const inputs = e.currentTarget.value;
    setSearchInput(inputs);
    clearTimeout(debonced);
    const timerExec = setTimeout(() => {
      resetQueryAndSetKeyword(inputs);
    }, 500);

    setDebonced(
      Number(timerExec),
    );
  };
  // for keybord enter event on global text search box
  const handleUserEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && searchInput.trim().length > 0) {
      // triger immediently
      searchNow();
    }
  };
  // reset paginations
  const resetPagination = React.useCallback(
    () => {
      setSkip(0);
      setCurrentPage(0);
    },
    [setSkip, setCurrentPage],
  );
  // ui control for date filter
  const handleDatesChange = (
    option: ValueType<OptionType>,
  ) => {
    setDateSelectedValue(option);
    resetPagination();
  };
  // ui control for xero filter
  const handleXeroSyncedChange = (
    option: ValueType<OptionType>,
  ) => {
    setXeroSyncedValue(option);
    resetPagination();
  };
  // ui control for channel filter
  const handleTeamChannelSelect = (options: ValueType<OptionType, true>) => {
    setTeamChannelInput((options) as unknown as Array<OptionType>);
    resetPagination();
  };
  // ui control for advance search type
  const handleAdvancedFilterTypeChange = (
    option: ValueType<OptionType>,
  ) => {
    setAdvancedFilterType(option);
    // clear the inputs if change the fiter type
    setAdvancedFilterInputs('');
  };
  // ui control for advaned search text
  const handleAdvancedFilterTextChange = (val: string) => {
    setAdvancedFilterInputs(val);

    clearTimeout(debonced);

    const timerExec = setTimeout(() => {
      resetQuaryAndSetAdvancedFilterQuery(val);
    }, 800);

    setDebonced(
      Number(timerExec),
    );
  };

  const reset = React.useCallback(
    () => {
      setDateSelectedValue(DATES_DEFAULT);
      setXeroSyncedValue({ label: '', value: '' });
      setTeamChannelInput([]);
      setKeyword('');
      setSearchInput('');
      setAdvancedFilterInputs('');
      setAdvancedFilterQuery('');
      setAdvancedFilterType(SEARCH_TYPE[0]);
      resetPagination();
    },
    [
      setDateSelectedValue,
      setXeroSyncedValue,
      setTeamChannelInput,
      setKeyword,
      setSearchInput,
      setAdvancedFilterInputs,
      setAdvancedFilterQuery,
      setAdvancedFilterType,
      resetPagination,
    ],
  );
  // show or hide advanced search
  const toggleAdvancedSearch = () => {
    clearTimeout(debonced);
    setIsOpen(!isOpen);
  };

  // when advanced search on/off,
  // reset default
  useEffect(() => {
    reset();
  }, [isOpen, reset]);

  // set event lisener for esc key
  useEffect(() => {
    const onESC = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setCurrentOrderID(undefined);
        setOrder(undefined);
      }
    };
    window.addEventListener('keydown', onESC);
    return () => {
      window.removeEventListener('keydown', onESC);
    };
  }, []);

  /**
   * side effect to
   * highlight current selection
   * will also work on re-sort
   * and also open the order detail view
   */
  useEffect(() => {
    const index = rows.findIndex((cur) => cur.key === currentOrderID);
    if (index >= 0) {
      setHighlightedRowIndex(index);
      setIsCompact(true);
      try {
        setOrder(data?.Orders?.orders![index]);
      } catch (err) {
        setOrder(undefined);
        setHighlightedRowIndex(undefined);
        setIsCompact(false);
      }
    } else {
      setHighlightedRowIndex(undefined);
    }
  }, [currentOrderID, rows, data]);

  /**
   * monitor if compact model
   */
  useEffect(() => {
    if (isCompact) {
      // set compacted columns option
      const compactedColumns = columns.map(((cur) => {
        if (COMPACT_MODEL_COLUMNS.includes(cur.name)) {
          return {
            ...cur,
            isChecked: true,
          };
        }
        return {
          ...cur,
          isChecked: false,
        };
      }));
      setColumns(compactedColumns);
    } else {
      setColumns(defaultColumns);
    }
  }, [isCompact]); // eslint-disable-line

  /**
    * monitor if order ===undefined
    */
  useEffect(() => {
    if (isUndefined(order)) {
      setIsCompact(false);
    }
  }, [order]);

  const closeOrdeView = () => {
    setCurrentOrderID(undefined);
    setOrder(undefined);
  };

  const handleRefetch = async () => {
    setIsRefetching(true);
    await refetch()
      .finally(() => setIsRefetching(false));
  };

  const isLoading = loading || isRefetching;
  return (
    <SplitView
      left={(
        <OrdersListView
          teamID={teamID}
          // for table
          head={head}
          rows={rows}
          onSort={onSort}
          sortKey={sortKey}
          sortOrder={sortOrder}
          highlightedRowIndex={highlightedRowIndex}
          // for UI
          error={error}
          isLoading={isLoading}
          isCompact={isCompact}
          // pagination
          pages={pages}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
          // customized columns,
          columns={columns}
          onColumnTick={onColumnTick}
          // status
          status={status}
          onStatusClick={onStatusClick}
          // global text search
          searchInput={searchInput}
          searchNow={searchNow}
          onSearchTextChange={onSearchTextChange}
          handleUserEnterKey={handleUserEnterKey}
          // advanced filter show/hide
          showAdvancedFilter={isOpen}
          toggleAdvancedSearch={toggleAdvancedSearch}
          // date filter
          dateSelectedValue={dateSelectedValue}
          handleDatesChange={handleDatesChange}
          // xero synced filter
          xeroSyncedValue={xeroSyncedValue}
          handleXeroSyncedChange={handleXeroSyncedChange}
          // channel filter
          teamChannelInput={teamChannelInput}
          handleTeamChannelSelect={handleTeamChannelSelect}
          // advanced filter type
          advancedFilterType={advancedFilterType}
          handleAdvancedFilterTypeChange={handleAdvancedFilterTypeChange}
          // advanced filter inputs
          advancedFilterInputs={advancedFilterInputs}
          handleAdvancedFilterTextChange={handleAdvancedFilterTextChange}
          // reset advanced filter to default,
          reset={reset}
          // order couts
          ordersTotal={ordersTotal}
          cursorFrom={cursorFrom}
          cursorTo={cursorTo}
          totalValue={totalValue}
          totalOutstanding={totalOutstanding}
          // refetch
          handleRefetch={handleRefetch}
           // pin overDue order
          shouldPinShipByOrder={shouldPinShipByOrder}
          handleShouldPinShipByOrder={
            (value:boolean) => setShouldPinShipByOrder(value)
          }
          exportReports={(
            <ExportReport values={undefined} teamId="" />
          )}
        />
      )}
      right={
        order
          ? (
            <OrderView
              order={order}
              closeOrdeView={closeOrdeView}
              refetch={handleRefetch}
            />
          )
          : null
      }
    />
  );
};
